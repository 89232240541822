import axios from "axios";

const $http = axios.create({
  // baseURL: "http://localhost:5198",
  baseURL: "https://www.ahhfky.com:7658",
  // baseURL: "http://221.229.106.180:7657",
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
});

// 添加请求拦截器
$http.interceptors.request.use(
  function (config) {
    if (config.params?.Params) {
      config.params.Params = JSON.stringify(config.params.Params);
    }
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

export default $http;
