<template>
  <router-view />
</template>

<style lang="less">
#app {
  font-family: Microsoft YaHei;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}
html,
body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

/*滚动条样式 */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #61b0f5;
}

::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: transparent;
}

/* .el-dropdown:focus-visible,
.el-dropdown-link:focus-visible {
  outline: none !important;
} */

.el-tooltip__trigger:focus {
  outline: none !important;
}

/* @media screen and (max-width: 750px) {
  html {
    font-size: 62.5%;
  }
} */

.index-new {
  width: calc(50% - 10px);
  background-color: #fff;
  margin-top: 10px;
  height: 303px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;

  & > .index-new-title {
    display: flex;
    align-items: center;
  }

  & > .index-new-content {
    flex: 1;
    font-size: 105%;
    color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;

    & > ul {
      height: 42px;
    }

    .index-new-content-title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-left: 20px;
      cursor: pointer;
      max-width: 100%;
      width: fit-content;
      margin-bottom: 1px;

      &:hover {
        border-bottom: 1px solid;
        margin-bottom: 0;
      }
    }
  }
}

.menu {
  cursor: pointer;

  &:hover {
    font-size: 110%;
    color: #3270b5;
  }
}

.menu-select {
  color: #3270b5;
}
</style>
