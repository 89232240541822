import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Index",
    component: () => import("../views/Index.vue"),
    children: [
      {
        path: "/",
        name: "Main",
        component: () => import("../views/Main.vue"),
      },
      {
        path: "/main/c/:id",
        name: "Content",
        component: () => import("../views/Content.vue"),
      },
      {
        path: "/main/m/:id",
        name: "Menu",
        component: () => import("../views/Menu.vue"),
      },
      {
        path: "/search/:key",
        name: "Search",
        component: () => import("../views/Search.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
