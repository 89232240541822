import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//加载动画样式
import "animate.css";

const app = createApp(App);

//#region 加载element ui
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import ElementPlus, { dayjs } from "element-plus";
import "element-plus/dist/index.css";
import zhCn from "element-plus/es/locale/lang/zh-cn";

//配置element图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.config.globalProperties[key] = key;
  app.component(key, component);
}
//#endregion

import http from "@/http";
app.config.globalProperties.$http = http;

app.config.globalProperties.$dayjs = dayjs;

app.config.globalProperties.$router = router;

app
  .use(store)
  .use(router)
  .use(ElementPlus, {
    locale: zhCn,
  })
  .mount("#app");
